import React from "react";
import "./Header.css";

//Renders Header
export default function Header() {
  return (
    <div className="header">
      <div className="header-title">Lista Rango</div>
    </div>
  );
}
